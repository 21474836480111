import React, { useEffect } from 'react'
import { graphql, useStaticQuery, navigate } from 'gatsby'
import Img, { FluidObject } from 'gatsby-image'
import tw from 'twin.macro'
import styled from '@emotion/styled'
import { BLOCKS, Block, Inline, Text } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { onPromoClick } from '../../../../sea-site-theme/src/components/utils/analytics'
import DolphinLogo from '../../../../sea-site-theme/src/svg/dolphin'
import DolphinWaveLogo from '../../../../sea-site-theme/src/svg/wave'
import { Triangle } from 'react-feather'

interface Items {
	body: {
		raw: string
	}
	image: {
		description: string
		title: string
		fluid: FluidObject
	}
	name: string
	url: string
}
/** Styled by Tailwindcss & Emotion */
const Section = tw.section`flex flex-col w-full justify-center items-center py-8 md:py-16 bg-gray-200`
const Title = tw.h2`font-display font-extrabold text-4xl text-center mb-2 text-blue-500`
const Subtitle = tw.h3`font-sans text-gray-600 text-2xl text-center px-4 mb-4 md:px-8 lg:mb-20`
const Items = styled.div`
	${tw`flex flex-col md:flex-row m-auto md:m-0 w-11/12 lg:w-11/12 xl:w-4/5 justify-center flex-wrap`}

	@media (max-width:767px) {
		${tw`items-center`}
	}
`
const Brand = styled.figure`
	${tw`bg-transparent py-1 px-2 w-full flex mb-6`}

	> div {
		${tw`flex w-full justify-center items-end`}
	}
`
const Item = styled.div<{ len: number }>`
	${tw`relative flex flex-col px-4 items-center mb-8`}
	${({ len }) => (len >= 2 ? tw`w-full md:w-6/12 lg:w-1/3 max-w-xl` : tw`w-full max-w-5xl md:flex-row`)};
	transition: background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
	overflow: hidden;
	.item-image {
		${tw`bg-gray-500`}
		transition: opacity 0.3s ease-in-out;
		width: 100%;
		height: auto;
		z-index: 0;
		img,
		picture {
			opacity: 0.875;
		}
	}
	div[role='link'] {
		${tw`cursor-pointer`}
		button:hover {
			transition: background-color 0.3s ease-in-out;
		}
	}
	&:hover {
		.item-image {
			opacity: 0.875;
		}
	}
	@media (max-width: 767px) {
		.item-image {
			display: none;
		}
		figure {
			${tw`relative bg-blue-500 py-2 h-48`}
		}
	}
`
const Content = styled.div<{ len: number }>`
	${tw`flex flex-col flex-1 pt-8 rounded-b items-center`}
	${({ len }) => (len >= 2 ? tw`w-full` : tw`w-full md:w-6/12 m-0 md:m-4 md:order-1`)};
	> p:last-of-type {
		${tw`pl-0 pr-6 mt-10 text-lg`}
		color: #666666;
	}
`
const ItemSubTitle = tw.h4`font-display font-bold text-2xl text-left w-full leading-tight mx-auto mb-6`
const Paragraph = styled.p`
	${tw`font-display relative pl-4`}
`
const UnorderedList = styled.ul`
	${tw`h-full flex flex-col justify-end w-full`}

	> li > p:before {
		position: absolute;
		content: '';
		height: 6px;
		width: 6px;
		left: 0;
		top: 5px;
		background: #58c8df;
		border-radius: 100%;
	}
`
const ItemImageHolder = styled.div<{ len: number }>`
	${tw`relative`}
	${({ len }) => (len >= 2 ? tw`w-full` : tw`w-full md:w-6/12 m-0 md:m-4 md:order-2`)};
	> div[role='link']:not(:first-of-type) {
		@media (min-width: 525px) {
			${tw`text-xl`}
		}
		transition: 0.3s ease-in-out;
		background-color: rgba(34, 41, 47, 0.3);
		${tw`text-white text-lg absolute flex bottom-0 w-full py-6 text-center justify-center items-center tracking-widest capitalize hover:bg-black`}
	}
`
const TriangleSvg = styled(Triangle)`
	${tw`ml-4`}
	transform: rotate(90deg);
`

const isBrowser = typeof window !== 'undefined'
/**
 * Lists featured brand products/services
 * TODO add domain and locale props
 */
const Featured = (): JSX.Element => {
	const { featured } = useStaticQuery(graphql`
		query getFeatured {
			featured: allContentfulFeaturedItems(filter: { contentful_id: { eq: "2ihBtuqfdv3KT8UzghmW5Q" } }) {
				edges {
					node {
						node_locale
						title
						subtitle
						items {
							name
							body {
								raw
							}
							url
							image {
								title
								description
								fluid(maxWidth: 600, maxHeight: 600, quality: 60, cropFocus: TOP) {
									...GatsbyContentfulFluid
								}
							}
						}
					}
				}
			}
		}
	`)
	/** Filter by locale */

	const [
		{
			node: { title, subtitle, items },
		},
	] = featured.edges

	const options = {
		renderNode: {
			[BLOCKS.PARAGRAPH]: function Parag(
				node: Block | Inline | Text,
				children: React.ReactChild | undefined | null | React.ReactFragment
			) {
				return <Paragraph>{children}</Paragraph>
			},
			[BLOCKS.HEADING_4]: function Heading4(
				node: Block | Inline | Text,
				children: React.ReactChild | undefined | null | React.ReactFragment
			) {
				return <ItemSubTitle>{children}</ItemSubTitle>
			},
			[BLOCKS.UL_LIST]: function List(
				node: Block | Inline | Text,
				children: React.ReactChild | undefined | null | React.ReactFragment
			) {
				return <UnorderedList>{children}</UnorderedList>
			},
		},
	}
	/** Promotion array */
	const promotions = items.map((promo: Items, i: number) => ({
		id: `${String(promo.name).toUpperCase()}_${i + 1}`,
		name: promo.name,
		creative: promo.image.description,
		position: `slot${i + 1}`,
	}))

	useEffect(() => {
		/**
		 * Google Analytics - Tag Manager - Enhanced Ecommerce
		 * Measuring promotion impressions onLoad
		 */
		if (isBrowser && window.dataLayer) {
			window.dataLayer.push({
				event: 'promotionView',
				ecommerce: {
					promoView: {
						promotions,
					},
				},
			})
		}
	}, [promotions, global])
	return (
		<Section>
			<Title>{title}</Title>
			<Subtitle>{subtitle}</Subtitle>
			<Items>
				{items.map((item: Items, index: number) => {
					return (
						<Item key={index} len={items.length}>
							<ItemImageHolder len={items.length}>
								<div
									tabIndex={-1}
									role="link"
									onClick={() => {
										onPromoClick(promotions[index], navigate('/' + item.url) as never)
									}}
									onKeyDown={() => {
										onPromoClick(promotions[index], navigate('/' + item.url) as never)
									}}
								>
									<Img fluid={item.image.fluid} alt={item.image.description} className="item-image" />
								</div>
								<div
									tabIndex={-1}
									role="link"
									onClick={() => {
										onPromoClick(promotions[index], navigate('/' + item.url) as never)
									}}
									onKeyDown={() => {
										onPromoClick(promotions[index], navigate('/' + item.url) as never)
									}}
								>
									Learn More
									<TriangleSvg color="#fff" fill="#fff" width="15" height="15" />
								</div>
							</ItemImageHolder>
							<Content len={items.length}>
								<Brand>
									{item.name === 'Dolphin' && (
										<div
											tabIndex={-1}
											role="link"
											onClick={() => {
												onPromoClick(promotions[index], navigate('/dolphin') as never)
											}}
											onKeyDown={() => {
												onPromoClick(promotions[index], navigate('/dolphin') as never)
											}}
										>
											<DolphinLogo width="150" height="70" color={'#353E47'} />
										</div>
									)}
									{item.name === 'Dolphin Wave' && (
										<div
											tabIndex={-1}
											role="link"
											onClick={() => {
												onPromoClick(promotions[index], navigate('/dolphin/commercial') as never)
											}}
											onKeyDown={() => {
												onPromoClick(promotions[index], navigate('/dolphin/commercial') as never)
											}}
										>
											<DolphinWaveLogo width="170" height="60" color={'#353E47'} />
										</div>
									)}
								</Brand>
								{item.body ? documentToReactComponents(JSON.parse(item.body.raw), options) : ''}
							</Content>
						</Item>
					)
				})}
			</Items>
		</Section>
	)
}

export default Featured
